export const HOST = 'https://www.rapi-fruti.com';
//export const HOST = 'http://192.168.1.37:4000';

//local:
//export const HOST = 'http://localhost:4000';

export const ROLE = ['Admin', 'Usuario'];

export enum ORDER_STATUS {
  PENDING = 'Pendiente',
  COMPLETE = 'Finalizado',
  CANCEL = 'Cancelado',
}

export enum ROUTE_NAME_OPTIONS {
  PRODUCTS_LIST = 'productsList',
  SHOPPING_CART = 'shopping-cart',
  CREATE_PRODUCT = 'create-product',
  USER_INFO = 'user-info',
  ORDERS = 'ordersList',
  LOGIN = 'login',
}

export const cantMinumunItems = 4;

export const timeZone = [
  { value: '09:00-13:00', label: '09:00 - 13:00' },
  { value: '13:00-17:00', label: '13:00 - 17:00' },
];

export const cities = [
  { value: 'Quilmes', label: 'Quilmes' },
  { value: 'Bernal', label: 'Bernal' },
  { value: 'Ezpeleta', label: 'Ezpeleta' },
  { value: 'Berazategui', label: 'Berazategui' },
  { value: 'Wilde', label: 'Wilde' },
  { value: 'Sarandi', label: 'Sarandi' },
  { value: 'Avellaneda', label: 'Avellaneda' },
];

export const optionsStatus = [
  { value: ORDER_STATUS.PENDING, label: 'Pendiente' },
  { value: ORDER_STATUS.COMPLETE, label: 'Finalizado' },
  { value: ORDER_STATUS.CANCEL, label: 'Cancelado' },
];

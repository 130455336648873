import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Product, ProductOptionValues } from '../ProductType.ts';
import './product-item.scss';
import '../product-list/product-list.scss';

interface ProductRowProps {
  product: Product;
  handleCartItemChange: (
    id: string,
    purchaseOption: string,
    quantity: number | string,
    productPrice: number,
  ) => void;
  handleAddToCart: (product: Product) => void;
}

const ProductRow = ({
  product,
  handleCartItemChange,
  handleAddToCart,
}: ProductRowProps) => {
  const [quantity, setQuantity] = useState<string | number>(1);
  const [purchaseOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const productOptions: { value: string; label: string }[] = [
    { value: ProductOptionValues.FULL_CRATE, label: 'cajon completo' },
    { value: ProductOptionValues.HALF_CRATE, label: '1/2 cajon' },
    { value: ProductOptionValues.UNIT, label: 'unidad' },
    { value: ProductOptionValues.KG, label: 'kg' },
    { value: ProductOptionValues.PACK, label: 'paquete' },
  ];

  const filteredOptions = (
    typeof product.purchaseOptions === 'string'
      ? product.purchaseOptions
          .split(',')
          .map((opt) => opt.trim().toLowerCase())
      : product.purchaseOptions.map((opt) => opt.toLowerCase())
  )
    .filter((opt) =>
      Object.values(ProductOptionValues).includes(opt as ProductOptionValues),
    )
    .map((opt) => productOptions.find((option) => option.value === opt))
    .filter(
      (opt): opt is { value: string; label: string } => opt !== undefined,
    );

  useEffect(() => {
    if (filteredOptions.length > 0 && !purchaseOption) {
      setSelectedOption(filteredOptions[0]);
    }
  }, [filteredOptions, purchaseOption]);

  useEffect(() => {
    handleCartItemChange(
      product.id,
      filteredOptions[0].value,
      1,
      filteredOptions[0].value === ProductOptionValues.HALF_CRATE
        ? parseInt(product.price) / 2
        : parseInt(product.price),
    );
  }, []);

  const handleOptionChange = (selected) => {
    setSelectedOption(selected);

    const productPrice =
      selected.value === 'half_crate'
        ? parseInt(product.price) / 2
        : parseInt(product.price);

    handleCartItemChange(
      product.id,
      selected.value,
      quantity === '' ? 0 : quantity,
      productPrice,
    );
  };

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }

    if (!purchaseOption || !purchaseOption.value) {
      console.error('Purchase option is missing!');
      return;
    }

    handleCartItemChange(
      product.id,
      purchaseOption.value,
      newQuantity,
      purchaseOption.value === ProductOptionValues.HALF_CRATE
        ? parseInt(product.price) / 2
        : parseInt(product.price),
    );
  };

  const buildWeightLabel = () => {
    if (!product?.boxWeight) return '';

    const boxWeight = product.boxWeight.includes('-')
      ? product.boxWeight.split('-').map(Number)
      : [Number(product.boxWeight)];

    let stringLabel = '';

    switch (purchaseOption?.value) {
      case ProductOptionValues.HALF_CRATE:
        stringLabel = boxWeight
          .map((weight) => {
            const halfWeight = weight / 2;
            return Number.isInteger(halfWeight)
              ? halfWeight
              : halfWeight.toFixed(2);
          })
          .join('-');
        break;

      case ProductOptionValues.KG:
        return '';

      default:
        stringLabel = boxWeight
          .map((weight) =>
            Number.isInteger(weight) ? weight : weight.toFixed(2),
          )
          .join('-');
        break;
    }

    return `${stringLabel}`;
  };

  return (
    <div className="product-card">
      <img
        src={product.image}
        alt={product.name}
        className="product-image margin-0"
      />
      <h3 className="product-title margin-0">{product.name}</h3>
      <p className="product-price margin-0">
        $
        {purchaseOption?.value === ProductOptionValues.HALF_CRATE
          ? parseInt(product.price) / 2
          : parseInt(product.price)}
      </p>

      <Select
        value={purchaseOption}
        options={filteredOptions}
        isSearchable={false}
        onChange={handleOptionChange}
        placeholder="opcion"
      />

      <div className="div-quantity-buttons">
        <button
          className="quantity-button margin-right-20"
          onClick={() => handleQuantityChange(parseInt(quantity) - 1)}
          disabled={quantity <= 1}
        >
          -
        </button>
        <span className="quantity-display">{quantity}</span>
        <button
          className="quantity-button margin-left-20"
          onClick={() => handleQuantityChange(parseInt(quantity) + 1)}
        >
          +
        </button>
      </div>

      <label className="product-weight">{`Peso: ${buildWeightLabel()} kg`}</label>

      <button className="add-to-cart" onClick={() => handleAddToCart(product)}>
        Agregar al carrito
      </button>
    </div>
  );
};

export default ProductRow;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { HOST } from '../../utils/constants.ts';
import { Order } from '../../product/context/ProductContext.tsx';

export interface User {
  id: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  description: string;
  isAdmin: boolean;
  orders: Order[];
  isVerified: boolean;
}
export interface UserInfo {
  user: User;
  token: string;
}

interface UserContextProps {
  userInfo: UserInfo | null;
  setUserInfo: (userInfo: UserInfo) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

// Función para decodificar el token y extraer el email
function decodeToken(token: string): any {
  const payload = token.split('.')[1];
  const decodedPayload = atob(payload);
  return JSON.parse(decodedPayload);
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(() => {
    const savedUserInfo = localStorage.getItem('userInfo');
    return savedUserInfo ? JSON.parse(savedUserInfo) : null;
  });

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const { user: email } = decodeToken(savedToken);
      fetch(`${HOST}/users/email/${email}`, {
        headers: {
          Authorization: `Bearer ${savedToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error al obtener datos del usuario');
          }
          return response.json();
        })
        .then((user) => {
          setUserInfo({ user, token: savedToken });
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  const updateUserInfo = (info: UserInfo) => {
    setUserInfo(info);
    localStorage.setItem('token', info.token);
  };

  function decodeToken(token: string): any {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload);
    return JSON.parse(decodedPayload); // Esto incluye el campo 'exp'
  }

  const logout = () => {
    setUserInfo(null);
    localStorage.clear();
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('token');

    if (savedToken) {
      const { exp } = decodeToken(savedToken);

      // Calcula el tiempo restante
      const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
      const timeRemaining = exp - currentTime;

      console.log('timeRemaining', timeRemaining);
      if (timeRemaining <= 0) {
        logout();
      } else {
        // Configura un temporizador para desloguear al usuario al expirar
        const timeout = setTimeout(logout, timeRemaining * 1000);

        return () => clearTimeout(timeout); // Limpia el temporizador al desmontar
      }
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ userInfo, setUserInfo: updateUserInfo, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

import React, { useCallback, useEffect, useState } from 'react';
import { FaCartShopping } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductContext } from '../../product/context/ProductContext.tsx';
import { useUserContext } from '../../user/context/UserContext.tsx';
import { ROUTE_NAME_OPTIONS } from '../../utils/constants.ts';
import { isLogged } from '../../utils/utils.ts';
import Button from '../button/Button.tsx';
import Dropdown from '../dropdown/Dropdown.tsx';
import './header.scss';

const Header = () => {
  const { userInfo } = useUserContext();
  const { shoppingCart } = useProductContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false); // Ocultar el header al scrollear hacia abajo
    } else {
      setIsVisible(true); // Mostrar el header al scrollear hacia arriba
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, handleScroll]);

  const dropdownOptions = [
    { label: 'Listado productos', routeName: ROUTE_NAME_OPTIONS.PRODUCTS_LIST },
    { label: 'Ver carrito', routeName: ROUTE_NAME_OPTIONS.SHOPPING_CART },
    ...(userInfo?.user?.isAdmin && isLogged()
      ? [
          {
            label: 'Crear Producto',
            routeName: ROUTE_NAME_OPTIONS.CREATE_PRODUCT,
          },
        ]
      : []),
    ...(isLogged()
      ? [
          { label: 'Mi Información', routeName: ROUTE_NAME_OPTIONS.USER_INFO },
          { label: 'Mis pedidos', routeName: ROUTE_NAME_OPTIONS.ORDERS },
        ]
      : []),
  ];

  const filteredOptions = dropdownOptions.filter(
    (option) => location.pathname !== `/${option.routeName}`,
  );

  const isShoppingCartScreen =
    location.pathname === `/${ROUTE_NAME_OPTIONS.SHOPPING_CART}`;

  const goToShoppingCart = () => {
    navigate(`/${ROUTE_NAME_OPTIONS.SHOPPING_CART}`);
  };

  const goToProducts = () => {
    navigate(`/${ROUTE_NAME_OPTIONS.PRODUCTS_LIST}`);
  };

  const goToLogin = (e) => {
    e.stopPropagation();
    navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
  };

  return (
    <div className={`buttons-position ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="left-section">
        <Button
          className="button margin-right-15 margin-responsive "
          onClick={goToProducts}
        >
          <img
            src="/rapi-fruti-logo.jpeg"
            alt="Logo"
            className="product-image margin-0"
          />
        </Button>
        <Dropdown items={filteredOptions} />
      </div>
      <div className="right-section">
        <div className="cart-icon-container">
          {!isLogged() && (
            <a onClick={goToLogin} className="login-prompt">
              Ingresa
            </a>
          )}
          {!isShoppingCartScreen && (
            <>
              <Button
                onClick={goToShoppingCart}
                className="button margin-responsive"
              >
                <FaCartShopping size={20} />
              </Button>
              {shoppingCart.totalItems > 0 && (
                <span className="cart-badge">{shoppingCart.totalItems}</span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

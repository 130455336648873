import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDebouncedCallback } from 'use-debounce';
import Header from '../../components/header/Header.tsx';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../components/modal/ConfirmModal.tsx';
import { useUserContext } from '../../user/context/UserContext.tsx';
import { HOST, ROUTE_NAME_OPTIONS } from '../../utils/constants.ts';
import { isLogged } from '../../utils/utils.ts';
import { useProductContext } from '../context/ProductContext.tsx';
import { Product } from '../ProductType.ts';
import './product-list.scss';
import ProductRow from './ProductRow.tsx';

const ProductList = () => {
  const {
    setSearchProduct,
    searchProduct,
    filteredProducts,
    addToCart,
    setProducts,
  } = useProductContext();
  const { userInfo } = useUserContext();
  const [cartItems, setCartItems] = useState<{
    [key: string]: {
      product: Product;
      purchaseOption: string;
      quantity: number;
      totalPrice: number;
    };
  }>({});

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 25;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct,
  );

  const navigate = useNavigate();

  // Función para cambiar la página
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(`${HOST}/products`);

      if (!response.ok) {
        throw new Error('Error al cargar los productos');
      }

      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [setProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts();
  }, [fetchProducts]);

  const handleCartItemChange = (id, purchaseOption, quantity, productPrice) => {
    setCartItems((prev) => ({
      ...prev,
      [id]: {
        product: filteredProducts.find((p) => p.id === id),
        purchaseOption: purchaseOption,
        quantity,
        totalPrice: quantity * productPrice,
      },
    }));
  };

  const goToLogin = () => {
    navigate(`/${ROUTE_NAME_OPTIONS.LOGIN}`);
  };

  const handleAddToCart = (product: Product) => {
    if (!isLogged()) {
      setIsOpenConfirmModal(true);
    } else {
      const cartItem = cartItems[product.id];

      if (
        !cartItem ||
        !cartItem.quantity ||
        isNaN(cartItem.quantity) ||
        cartItem.quantity <= 0
      ) {
        toast.error(
          `Por favor ingresa la cantidad de ${product.name} que desees`,
          { duration: 4000 },
        );
        return;
      }

      addToCart(cartItem);
      toast.success(`Se agregó ${product.name} al carrito`, { duration: 4000 });
    }
  };

  return (
    <div className="body-product">
      {isOpenConfirmModal && (
        <ConfirmModal
          confirmText={'Ingresa'}
          bodyText={'Necesitas estar logeado para poder realizar tu pedido'}
          action={goToLogin}
          cancel={() => setIsOpenConfirmModal(false)}
        />
      )}
      <Header />
      <div className="margin-top-80">
        {isLogged() && (
          <div className="flex-welcome">
            <h2 className="welcome-title">Hola</h2>
            <h2 className="welcome-title"> {userInfo?.user?.name}</h2>
          </div>
        )}

        <div className="price-notice-position">
          <p className="price-notice">
            Los precios pueden variar. Los precios exactos se cargan los días
            martes.
          </p>
        </div>
      </div>

      <h4>
        Si deseas algun producto que no esta en la lista no dudes en
        consultarnos y trataremos de conseguirlo
      </h4>

      <h4>
        Zonas de envío: Quilmes, Bernal, Ezpeleta, Berazategui, wilde,
        Avellaneda, Sarandi
      </h4>

      <h3 className="product-title">Listado de Frutas y Verduras</h3>

      <div className="flex-center">
        <input
          className="input flex-half"
          placeholder="busca tu producto..."
          value={searchProduct}
          onChange={(e) => setSearchProduct(e.target.value)}
        />
      </div>

      <div className="product-list margin-top-10">
        {currentProducts && currentProducts.length > 0
          ? currentProducts.map((product) => (
              <ProductRow
                key={product.id}
                product={product}
                handleCartItemChange={handleCartItemChange}
                handleAddToCart={handleAddToCart}
              />
            ))
          : null}
      </div>
      {(!currentProducts || currentProducts.length === 0) && (
        <p className="no-products-message">
          No hay resultados para tu búsqueda
        </p>
      )}

      <div className="pagination">
        {filteredProducts.length > productsPerPage && (
          <div className="page-numbers">
            {Array.from(
              { length: Math.ceil(filteredProducts.length / productsPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  className={`page-number ${
                    currentPage === index + 1 ? 'active' : ''
                  }`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
